//HERO STYLES
#hero {
  margin-top: 100px;
  width: 100%;
  height: 90vh;
  position: relative;
}

.hero-image {
  background-position: center center;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
}

.owl-carousel {
  //position: relative;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  bottom: 65px;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 7px;
  background: rgba(230, 235, 240, 0.432);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 40px;
  transition: all 1s;
  border: 1px solid white;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: white;
}

.customNextBtn {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  font-size: 30px;
  color: #E6EBF0;
  transition: all 1s;
  z-index: 1000;
}

.customPrevBtn:hover,
.customNextBtn:hover {
  color: white;
}

.customPrevBtn {
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 50%;
  font-size: 30px;
  color: #E6EBF0;
  z-index: 1000;
  transition: all 1s;
}

.hero-image h1 {
  color: white;
  font-size: 32px;
  font-weight: bolder;
}

.hero-image h2 {
  color: white;
  font-size: 20px;
  font-style: italic;
  font-weight: lighter;
}

.text-hero-left {
  width: 40%;
  position: relative;
  left: 10%;
}

.text-hero-right {
  width: 40%;
  position: relative;
  right: -50%
}

//styles for puntos
#puntos-envio {
  position: relative;
  background-color: #2a97dc;
  color: white;
  padding-bottom: 50px;

  .owl-theme .owl-dots,
  .owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    bottom: -20px;
    margin-top: 15px;
  }

  .box-envio {
    padding: 100px 120px 100px 50px;
  }

  h1 {
    font-weight: 600;
    font-size: 50px;
  }

  p {
    font-weight: normal;
    font-size: 22px;
  }
}

#carousel_puntos {
  margin-top: 50px;
}

#titulo-info {
  position: relative;
  margin-top: 50px;
  margin-bottom: 0px;
  color: #0098DA;
  font-size: 55px;
  font-weight: 700;
  text-align: center;
}

.container_video,
.upper_box {
  background-image: linear-gradient(to bottom, white 0%, white 10%, white 20%, white 30%, white 40%, white 49.99999999%, #2a97dc 50%);
  padding: 50px 0px;
}

#video {
  margin: 0 auto;
  background-color: #F6F6F6;
  height: 500px;
  width: 880px;
  padding: 7px;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
}

.video_mp4 {
  position: absolute;
}

#video-background {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

#video_yt {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

#play {
  width: 115px;
  z-index: 10;
  cursor: pointer;
  opacity: 0.7;
  transition: all 1s;
}

#play:hover {
  opacity: 1;
}

$blue: #0399DB;
$darker_blue: #19253D;

#localiza-envio,
#punto_pago {
  padding: 50px 0px;
  text-align: center;

  h1,
  h3 {
    color: $blue;
  }

  h2 {
    color: $darker_blue;
    font-size: 25px;
    font-weight: lighter;
    width: 50%;
    margin: 35px auto;
    margin-bottom: 60px;
  }

  h1 {
    font-weight: 600;
    font-size: 50px;
  }

  p {
    font-weight: normal;
    font-size: 22px;
  }

  h3 {
    font-weight: 600;
    font-family: Dosis, sans-serif;
    font-size: 23px;
  }

  h4 {
    font-family: Lato, sans-serif;
    font-size: 16px;
  }
}

#formularios {
  padding: 50px 0px;
  text-align: center;

  h1 {
    color: $blue;
  }

  h2 {
    color: $darker_blue;
    font-size: 25px;
    font-weight: lighter;
    width: 50%;
    margin: 35px auto;
    margin-bottom: 30px;
  }

  h1 {
    font-weight: 600;
    font-size: 50px;
  }

  p {
    font-weight: normal;
    font-size: 22px;
  }
}

.buscador {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;

  select {
    float: left;
    display: inline-block;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 60px;
    line-height: 60px;
  }

  button {
    display: inline-block;
    float: left;
    width: 20%;
    background-color: $blue;
    transition: all 1s;
    color: white;
    border: none;
    box-shadow: none;
    font-weight: bolder;
    border-radius: 0px;
    height: 60px;
    line-height: 60px;
  }

  button:hover {
    background-color: rgb(46, 66, 107);
  }
}

#mapa_envio,
#mapa_pago {
  height: 80vh;
  width: 100%;
}

#cobra {
  position: relative;
  padding-top: 50px;

  .box-envio {
    padding: 100px 120px 100px 50px;
  }

  .box-envio h1 {
    color: $blue;
    font-weight: 600;
    font-size: 50px;
  }

  .box-envio p {
    color: $darker_blue;
    font-weight: normal;
    font-size: 22px;
  }

  .box-envio span {
    color: #FED201;
  }

  .slider_box {
    margin: 0 auto;
    background-color: #F6F6F6;
    width: 80%;
    padding: 7px;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);

    .owl-theme .owl-dots,
    .owl-theme .owl-nav {
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      bottom: 70px;
    }

    #carousel_ecuador {}
  }

  .blue_stuff {
    background-color: $blue;
    color: white;
    text-align: center;
    padding-bottom: 60px;

    h1 {
      font-weight: bolder;
      font-size: 50px;
    }

    h3 {
      margin-top: 15px;
      font-weight: normal;
      font-size: 25px;
      margin-bottom: 50px;
    }

    span {
      font-style: italic;
    }

    .owl-theme .owl-dots,
    .owl-theme .owl-nav {
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      bottom: -20px;
      margin-top: 15px;
    }
  }
}

#beneficios {
  padding-top: 50px;

  .box-envio {
    padding: 100px 80px 100px 50px;
  }

  .beneficios_copy,
  .box-envio h1 {
    color: $blue;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 40px;
  }

  .box-envio p {
    color: $darker_blue;
    font-weight: normal;
    font-size: 22px;
  }

  span {
    font-style: italic;
  }

  .beneficios_copy {
    text-align: center;
  }

  select {
    margin: 0 auto;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: left;
  }
}

select {
  border: #E2E2E2 solid 1px;
  color: #6F6F6F;
}

.proveedores {
  margin: 50px 0px;
}

.proveedor {
  text-align: center;
  padding: 50px 15px;
  cursor: pointer;
  transition: all 1s;
  z-index: 1;
  background-color: white;

  h4 {
    color: rgb(54, 54, 54);
    font-weight: normal;
    font-size: 20px;
    padding: 20px;
  }

  height: 250px;

  img {
    width: 80%;
  }
}



.proveedores .proveedor:nth-child(n+5) {
  border-top: 1px solid rgba(54, 54, 54, 0.582);
}

.proveedores .proveedor:nth-child(4n-1),
.proveedores .proveedor:nth-child(4n-2),
.proveedores .proveedor:nth-child(4n) {
  border-left: 1px solid rgba(54, 54, 54, 0.582);
}

.proveedores .proveedor:only-child {
  border: none;
}

.proveedor:hover {
  border: 1px solid rgba(54, 54, 54, .582) !important;
  transform: scale(1.2);
  z-index: 100;
}

.select {
  -webkit-border-radius:  !important;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius:  !important;
  /* Firefox 1-3.6 */
  border-radius:  !important;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}

#formularios {
  color: #787878;
  font-size: 20px;

  input[type=text],
  input[type=email] {
    box-shadow: none;
    float: left;
    width: 33%;
    font-style: italic;
    border-bottom: 2px solid #E2E2E2;
    border-top: 2px solid #E2E2E2;
    margin: 0;
    padding: 20px;
  }

  input[type=text]:nth-child(1) {
    border: 2px solid #E2E2E2;
  }

  input[type=email] {
    border: 2px solid #E2E2E2;
  }

  input[type=text]:nth-child(2) {
    border-left: none;
    border-right: none;
  }

  textarea {
    font-style: italic;
    padding: 20px;
    resize: none;
    width: 99%;
    float: left;
    height: 200px;
    border: 2px solid #E2E2E2;
    border-top: none;
  }

  #enviar {
    font-weight: bolder;
    font-size: 17px;
    margin: 40px auto;
    padding: 12px 75px;
    background-color: #0399db;
    color: #fff;
    border: none;
    box-shadow: none;
    transition: all 1s;
    border-radius: 91px;
    text-transform: uppercase;
  }

  #enviar:hover {
    background-color: darken($blue, 30)
  }

  select {
    margin: 20px auto;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: left;
  }
}

#terminos {
  margin-top: 150px;
  margin-botton: 80px;

  //texto #19253D  amarillo #F8DC3A  blue #0098DB
  .panel-heading a:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    transition: all 0.5s;
    font-style: normal;
  }

  .panel-heading a.collapsed:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .panel-heading {
    background-color: white;
    border: 1px solid #c1c1c1;
    font-style: italic;
    transition: all 1s;

    a:hover {
      text-decoration: none;
    }
  }

  .panel-heading:hover {
    background-color: rgb(245, 245, 245);
  }

  .panel {
    margin: 40px 0px;
  }

  .panel-body,
  .panel {
    border: none;
    box-shadow: none;
  }

  h1 {
    color: #0098DB;
    font-size: 60px;
    text-align: center;
    font-weight: 700;
  }

  .actualizacion {
    color: #c1c1c1;
    font-size: 14px;

    span {
      color: #FED201;
    }
  }

  p {
    color: #19253D;
    font-size: 16px;
  }

  .button {
    margin-bottom: 60px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #0098da;
    margin: 20px auto;
    transition: all 1s;
    padding: 20px 50px;
    font-size: 20px;
  }

  .button:hover {
    background-color: darken(#0098da, 10);
    text-decoration: none;
  }
}

p,
h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

h1 {
  font-family: 'Dosis', sans-serif;
}

#puntos-envio p {
  font-weight: 300;
  font-size: 20px;
}

.hero-image h2 {
  color: #fff;
  font-size: 19px;
  font-style: normal;
  font-weight: lighter;
  margin-top: -5px;
}

#localiza-envio h2,
#punto_pago h2 {
  color: #19253d;
  font-size: 20px;
  font-weight: 300;
  width: 50%;
  margin: 35px auto;
  margin-bottom: 60px;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

#beneficios_modal {
  display: none;
}

#carousel_puntos_cobro {
  margin-bottom: 50px;
}

.item-puntos1 {
  width: 50%;
  text-align: right;
  float: left;
  background-color: #19a1e3;
  padding-right: 3%;
}

.item-puntos2 {
  width: 50%;
  text-align: left;
  float: left;
  background-color: #19a1e3;
  padding-top: 10px;
  padding-left: 3%;
}

.item-puntos3 {
  width: 100%;
  text-align: center;
  background-color: #19a1e3;
  height: 80px;
  padding-left: 3%;
  padding-bottom: 20px;
}

#cobra .blue_stuff {
  background-color: #19a1e3;
  color: #fff;
  text-align: center;
  padding-bottom: 0px;
}

#cobra .blue_stuff h3 {
  margin-top: 25px;
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 50px;
  font-family: 'Lato', sans-serif;
}

#header-custom #navbar li a {
  color: #5a5a5a;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  transition: all 1s;
  font-family: 'Lato', sans-serif;
}

#header-custom #navbar li a span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -23px;
  left: 0;
  background-color: #e8e8e8;
  transform: scaleX(1);
  transition: all .3s ease-in-out 0s;
}

#cobra .box-envio p {
  color: #19253d;
  font-weight: 300;
  font-size: 20px;
}

#cobra .box-envio a {
  color: #337ab7;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  transition: all .3s;
  font-style: italic;
}

#cobra .box-envio a:hover {
  color: #fed201;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  transition: all .3s;
}

.proveedor {
  text-align: center;
  padding: 20px 15px;
  height: 250px;
}

.proveedor h4 {
  color: #363636;
  font-weight: 300;
  font-size: 20px;
  padding: 20px;
  font-family: 'Lato', sans-serif;
}

#beneficios .box-envio p {
  color: #19253d;
  font-weight: 300;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}

#formularios h2 {
  color: #19253d;
  font-size: 20px;
  font-weight: lighter;
  width: 50%;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  margin: 35px auto;
  margin-bottom: 30px;
}

footer hr {
  margin: 50px auto 10px;
  width: 70%;
  border: 2px solid #787878;
}

#formularios #enviar {
  font-weight: bolder;
  font-size: 17px;
  margin: 40px auto;
  padding: 12px 75px;
  background-color: #0399db;
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 1s;
  border-radius: 91px;
  text-transform: uppercase;
}

#puntos-envio {
  background-color: #19a1e3;
}

.container_video,
.upper_box {
  background-image: linear-gradient(180deg, #fff 0, #fff 10%, #fff 20%, #fff 30%, #fff 40%, #fff 49.99999999%, #19a1e3 50%);
  padding: 50px 0;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 28px;
}

.modal-header .close {
  margin-top: -2px;
  font-size: 30px;
}

.modal-body {
  position: relative;
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

.modal_title {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

#boton {
  font-weight: bolder;
  font-size: 14px;
  margin: 5px auto;
  padding: 10px 70px;
  background-color: #0399db;
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 1s;
  border-radius: 91px;
  text-transform: uppercase;
}
.mobile{
  display: none;
}

@media screen and (max-width:768px) {
  #terminos h1 {
    font-size: 30px;
  }
  .mobile{
    display: unset;
  }
  .desktop{
    display: none !important;
  }
  #terminos .button {
    margin: 12px auto;
    transition: all 1s;
    padding: 20px 50px;
    font-size: 11px;
  }

  footer hr {
    margin: 10px auto 10px !important;
  }

  #beneficios select {
    width: 70% !important;
  }

  #formularios select {
    margin: 20px auto;
    width: 70% !important;
    font-size: 17px !important;
    text-align: left;
  }

  input[type=text],
  input[type=email] {
    box-shadow: none;
    float: left;
    width: 100% !important;
    font-size: 17px !important;
    font-style: italic;
    margin: 0;
    padding: 20px;
  }

  input[type=text]:nth-child(1) {
    border: 2px solid #E2E2E2 !important;
  }

  input[type=email] {
    border-left: 2px solid #E2E2E2 !important;
    border-right: 2px solid #E2E2E2 !important;
    border-top: 2px solid #E2E2E2 !important;
  }

  input[type=text]:nth-child(2) {
    border-left: 2px solid #E2E2E2 !important;
    border-right: 2px solid #E2E2E2 !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  textarea {
    font-style: italic;
    padding: 20px;
    resize: none;
    width: 99%;
    float: left;
    height: 200px;
    border: 2px solid #E2E2E2;
    border-top: none;
  }

  #formularios video,
  #beneficios video,
  #cobra video {
    width: 80%;
  }

  .buscador button,
  .buscador select {
    font-size: 12px;
  }

  #formularios h2 {
    width: 80%;
  }

  #formularios h1 {
    font-size: 38px;
  }

  .mobile_center {
    text-align: center;
  }

  #beneficios .beneficios_copy,
  #beneficios .box-envio h1 {
    font-size: 38px;
    margin-bottom: 21px;
  }

  #beneficios .box-envio {
    padding: 20px 40px;
  }

  #localiza-envio h2,
  #punto_pago h2 {
    width: 80%;
    margin: 35px auto;
    margin-bottom: 40px;
  }

  #localiza-envio h1,
  #punto_pago h1 {
    font-size: 38px;
    margin-top: 0px;
  }

  #cobra .slider_box .owl-theme .owl-dots,
  #cobra .slider_box .owl-theme .owl-nav {
    bottom: 20%;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 7px;
  }

  #cobra .box-envio {
    padding: 10px 40px;
  }

  #cobra .box-envio h1 {
    font-size: 40px;
  }

  #cobra .slider_box {
    width: 90%;
    padding: 3px;
  }

  #cobra,
  #beneficios {
    padding-top: 0;
  }

  #info img {
    width: 80%;
  }

  #play {
    width: 115px !important;
  }

  #puntos-envio .box-envio {
    padding: 10px 30px;
  }

  #video {
    height: 221px;
    width: 389px;
    padding: 2px;
  }

  #titulo-info {
    font-size: 35px;
  }

  .customPrevBtn,
  .customNextBtn {
    display: none;
  }

  #hero,
  .hero-image {
    width: 100%;
    height: 80vh;
  }

  .text-hero-left {
    width: 75%;
    position: unset;
    margin: 0 auto;
  }

  .text-hero-right {
    width: 75%;
    position: unset;
    margin: 0 auto;
  }

  .hero-image h1 {
    font-size: 22px;
  }

  .hero-image h2 {
    font-size: 17px;
  }
  
}
