h1,
h2,
h3,
h4,
h5,
p,
input,
textarea,
a,
body {
    font-family: 'Lato', sans-serif;
}

