h1,
h2,
h3,
h4,
h5,
p,
input,
textarea,
a,
body {
  font-family: 'Lato', sans-serif; }

.magic_hover:hover > span,
.magic_hover:hover > i {
  -webkit-transition: ease-in .4s;
  -o-transition: ease-in .4s;
  transition: ease-in .4s;
  color: #7A2A00;
  text-decoration: none;
  font-weight: bold; }

.logo {
  height: 55px;
  margin-top: 12.5px; }

#header-custom .navbar-brand > span {
  color: white; }

@media screen and (max-width: 992px) {
  #header-custom .navbar-brand {
    padding-left: 55px !important;
    padding-right: 0 !important; }
  #header-custom .navbar-brand:after,
  #header-custom .navbar-brand:before {
    margin-left: 25px; } }

#header-custom.navbar-fixed-top {
  position: fixed !important;
  background: #fff;
  -ms-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  top: 0; }

#header-custom.navbar-fixed-top .navbar-brand {
  color: #cfc91d; }

#header-custom.navbar-fixed-top #navbar li a {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

#header-custom.navbar-fixed-top #navbar li a:hover {
  color: pink; }

@media screen and (max-width: 992px) {
  #header-custom.navbar-fixed-top #navbar li a {
    font-size: 20px; } }

#header-custom.navbar-fixed-top #navbar li.active a {
  color: pink !important; }

@media screen and (max-width: 768px) {
  #header-custom.navbar-fixed-top #navbar li.active a {
    color: pink !important; } }

#header-custom.navbar-fixed-top #navbar li.active a span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
     transform: scaleX(1);
  background-color: pink; }

@media screen and (max-width: 768px) {
  #header-custom.navbar-fixed-top #navbar li.active a span:before {
    background-color: transparent; } }

.socialmedia {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 70px;
  padding-right: 70px;
  min-height: 35px; }
  .socialmedia .redes_sociales {
    color: #666666;
    padding: 5px;
    margin: 0px;
    font-size: 16px;
    border-left: 1px solid #E2E2E2;
    border-right: 1px solid #E2E2E2;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s; }
  .socialmedia .redes_sociales:hover {
    color: #0399DB;
    text-decoration: none; }
  .socialmedia .redes_sociales:first-child,
  .socialmedia .redes_sociales:last-child,
  .socialmedia .redes_sociales:nth-child(3) {
    border: none; }

#header-custom .navbar-default {
  border-bottom: 1px solid #e2e2e2;
  border: transparent;
  background: white;
  margin: 0;
  padding-left: 50px;
  height: 80px;
  padding-right: 50px;
  border-radius: 0px; }

@media screen and (max-width: 768px) {
  #header-custom .navbar-default {
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px; } }

.social {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  width: 100%; }

.social li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block; }

.social li a {
  font-size: 16px;
  display: table;
  width: 40px;
  height: 40px;
  margin: 0 4px; }

.social li a i {
  display: table-cell;
  vertical-align: middle; }

.social li a:hover,
.social li a:active,
.social li a:focus {
  text-decoration: none;
  border-bottom: none; }

.social li a.social-box {
  background: white;
  color: #fff; }

.social li a.social-circle {
  background: white;
  color: #fff;
  border-radius: 50%; }

.social li a:hover {
  background: #393e46 !important; }

.social.social-box a {
  background: white;
  color: #fff; }

.social.social-circle a {
  background: white;
  color: #fff;
  border-radius: 50%; }

#header-custom {
  position: fixed;
  border-bottom: 1px solid #e2e2e2;
  z-index: 99;
  width: 100%;
  opacity: 1;
  top: 0; }

#header-custom .navbar {
  padding-bottom: 0;
  margin-bottom: 0; }

#header-custom #navbar .navbar-right {
  margin-right: 0;
  text-align: center;
  margin-top: 20px; }

@media screen and (max-width: 768px) {
  .logo {
    margin-left: 30px; }
  .socialmedia {
    height: 40px;
    width: 100%;
    background-color: white;
    padding-left: 18px;
    padding-right: 18px;
    line-height: 30px; } }

@media screen and (max-width: 992px) {
  #header-custom #navbar .navbar-right {
    padding-bottom: 20px; } }

#header-custom #navbar li a {
  color: #5a5a5a;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

@media screen and (max-width: 992px) {
  #header-custom #navbar li a {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 20px; } }

#header-custom #navbar li a span {
  position: relative;
  display: block;
  padding-bottom: 2px; }

#header-custom #navbar li a span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -23px;
  left: 0;
  background-color: #E8E8E8;
  /* visibility: hidden; */
  transform: scaleX(1);
  -webkit-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

#header-custom #navbar li a:hover {
  color: #19253D; }

#header-custom #navbar li a:hover span:before {
  background-color: #19253D;
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1); }

#header-custom #navbar li.active a {
  background: transparent;
  background: none; }

#header-custom #navbar li.active a span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
     transform: scaleX(1); }

@media screen and (max-width: 480px) {
  #header-custom #navbar li.active a span:before {
    background-color: transparent; } }

@media screen and (max-width: 992px) {
  #header-custom #navbar li.active a {
    background: transparent;
    background: none; } }

@media screen and (max-width: 992px) {
  #header-custom #navbar li.active a span {
    display: inline-block; }
  #header-custom #navbar li.active a span:before {
    bottom: 0;
    height: 0;
    background: transparent; } }

#header-custom .navbar-brand {
  float: left;
  display: block;
  font-size: 24px;
  font-weight: 700;
  padding-left: 28px;
  color: #cfc91d;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative; }

.nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none; }

.nav-toggle.active i::before,
.nav-toggle.active i::after {
  background: greenyellow; }

.nav-toggle:hover,
.nav-toggle:focus,
.nav-toggle:active {
  outline: none;
  border-bottom: none !important; }

.nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 25px;
  height: 3px;
  color: #535659;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #535659;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.nav-toggle i::before,
.nav-toggle i::after {
  content: '';
  width: 25px;
  height: 3px;
  background: #535659;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s; }

.nav-toggle i::before {
  top: -7px; }

.nav-toggle i::after {
  bottom: -7px; }

.nav-toggle:hover i::before {
  top: -10px; }

.nav-toggle:hover i::after {
  bottom: -10px; }

.nav-toggle.active i {
  background: transparent; }

.nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg); }

.nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg); }

.nav-toggle {
  position: absolute;
  top: 22px;
  right: 30px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 25px;
  border-bottom: none !important; }

@media screen and (max-width: 768px) {
  .nav-toggle {
    display: block; } }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-width: 0; }

@media screen and (min-width: 768px) {
  .nav-tabs > li > a {
    border: none;
    color: pink;
    background-color: #56565A;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; } }

.nav-tabs > li.active > a,
.nav-tabs > li > a:hover {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: none;
  color: pink !important;
  background: #F2F2F2; }

.nav-tabs > li.active > a {
  background: #F2F2F2; }

.nav-tabs > li.active {
  background: #F2F2F2; }

.nav-tabs > li.active > a:active {
  background: #F2F2F2; }

.tab-pane {
  padding: 15px 0; }

@media screen and (min-width: 768px) {
  .tab-content {
    padding: 20px;
    background-color: #F2F2F2;
    height: 500px;
    padding-bottom: 100px;
    padding-right: 100px;
    padding-left: 100px; } }

.card {
  background: #F2F2F2; }

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  width: 25%; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: #F2F2F2; }

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: none; }

@media screen and (max-width: 768px) {
  #header-custom #navbar li a:hover span:before {
    visibility: hidden;
    display: none; }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
    margin-top: 11.5px;
    background-color: white; }
  #header-custom #navbar li a span:before {
    display: none; } }

footer {
  padding-bottom: 40px; }
  footer h1 {
    color: #0399DB;
    font-size: 25px;
    font-weight: bolder; }
  footer .legales {
    display: block;
    margin: 5px auto;
    color: #787878;
    font-size: 16px;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s; }
  footer .legales:hover {
    color: #19253D;
    text-decoration: none; }
  footer .yellow {
    color: #FFD008;
    font-size: 16px; }
  footer .texto_yellow {
    color: #787878;
    font-size: 14px; }
  footer hr {
    margin: 10px auto 10px auto;
    width: 70%;
    border: 2px solid #787878; }
  footer h3 {
    color: #787878;
    font-size: 15px; }
  footer h4 {
    color: #787878;
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0px; }
  footer .redes_sociales {
    color: #666666;
    padding: 10px;
    margin: 10px 0px 0px 0px;
    font-size: 16px;
    border-left: 1px solid  #E2E2E2;
    border-right: 1px solid  #E2E2E2;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s; }
  footer .redes_sociales:hover {
    color: #0399DB;
    text-decoration: none; }
  footer .redes_sociales:first-child, footer .redes_sociales:last-child, footer .redes_sociales:nth-child(3) {
    border: none; }

@media screen and (max-width: 768px) {
  footer h3 {
    text-align: center; }
  footer .text-right {
    text-align: center; } }

#hero {
  margin-top: 100px;
  width: 100%;
  height: 90vh;
  position: relative; }

.hero-image {
  background-position: center center;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  bottom: 65px; }

.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 7px;
  background: rgba(230, 235, 240, 0.432);
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease;
  border-radius: 40px;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  border: 1px solid white; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: white; }

.customNextBtn {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  font-size: 30px;
  color: #E6EBF0;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  z-index: 1000; }

.customPrevBtn:hover,
.customNextBtn:hover {
  color: white; }

.customPrevBtn {
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 50%;
  font-size: 30px;
  color: #E6EBF0;
  z-index: 1000;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

.hero-image h1 {
  color: white;
  font-size: 32px;
  font-weight: bolder; }

.hero-image h2 {
  color: white;
  font-size: 20px;
  font-style: italic;
  font-weight: lighter; }

.text-hero-left {
  width: 40%;
  position: relative;
  left: 10%; }

.text-hero-right {
  width: 40%;
  position: relative;
  right: -50%; }

#puntos-envio {
  position: relative;
  background-color: #2a97dc;
  color: white;
  padding-bottom: 50px; }
  #puntos-envio .owl-theme .owl-dots,
  #puntos-envio .owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    bottom: -20px;
    margin-top: 15px; }
  #puntos-envio .box-envio {
    padding: 100px 120px 100px 50px; }
  #puntos-envio h1 {
    font-weight: 600;
    font-size: 50px; }
  #puntos-envio p {
    font-weight: normal;
    font-size: 22px; }

#carousel_puntos {
  margin-top: 50px; }

#titulo-info {
  position: relative;
  margin-top: 50px;
  margin-bottom: 0px;
  color: #0098DA;
  font-size: 55px;
  font-weight: 700;
  text-align: center; }

.container_video,
.upper_box {
  background-image: -webkit-linear-gradient(top, white 0%, white 10%, white 20%, white 30%, white 40%, white 49.99999999%, #2a97dc 50%);
  background-image: -o-linear-gradient(top, white 0%, white 10%, white 20%, white 30%, white 40%, white 49.99999999%, #2a97dc 50%);
  background-image: linear-gradient(to bottom, white 0%, white 10%, white 20%, white 30%, white 40%, white 49.99999999%, #2a97dc 50%);
  padding: 50px 0px; }

#video {
  margin: 0 auto;
  background-color: #F6F6F6;
  height: 500px;
  width: 880px;
  padding: 7px;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3); }

.video_mp4 {
  position: absolute; }

#video-background {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

#video_yt {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 11; }

#play {
  width: 115px;
  z-index: 10;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }

#play:hover {
  opacity: 1; }

#localiza-envio,
#punto_pago {
  padding: 50px 0px;
  text-align: center; }
  #localiza-envio h1,
  #localiza-envio h3,
  #punto_pago h1,
  #punto_pago h3 {
    color: #0399DB; }
  #localiza-envio h2,
  #punto_pago h2 {
    color: #19253D;
    font-size: 25px;
    font-weight: lighter;
    width: 50%;
    margin: 35px auto;
    margin-bottom: 60px; }
  #localiza-envio h1,
  #punto_pago h1 {
    font-weight: 600;
    font-size: 50px; }
  #localiza-envio p,
  #punto_pago p {
    font-weight: normal;
    font-size: 22px; }
  #localiza-envio h3,
  #punto_pago h3 {
    font-weight: 600;
    font-family: Dosis, sans-serif;
    font-size: 23px; }
  #localiza-envio h4,
  #punto_pago h4 {
    font-family: Lato, sans-serif;
    font-size: 16px; }

#formularios {
  padding: 50px 0px;
  text-align: center; }
  #formularios h1 {
    color: #0399DB; }
  #formularios h2 {
    color: #19253D;
    font-size: 25px;
    font-weight: lighter;
    width: 50%;
    margin: 35px auto;
    margin-bottom: 30px; }
  #formularios h1 {
    font-weight: 600;
    font-size: 50px; }
  #formularios p {
    font-weight: normal;
    font-size: 22px; }

.buscador {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px; }
  .buscador select {
    float: left;
    display: inline-block;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 60px;
    line-height: 60px; }
  .buscador button {
    display: inline-block;
    float: left;
    width: 20%;
    background-color: #0399DB;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    color: white;
    border: none;
    box-shadow: none;
    font-weight: bolder;
    border-radius: 0px;
    height: 60px;
    line-height: 60px; }
  .buscador button:hover {
    background-color: #2e426b; }

#mapa_envio,
#mapa_pago {
  height: 80vh;
  width: 100%; }

#cobra {
  position: relative;
  padding-top: 50px; }
  #cobra .box-envio {
    padding: 100px 120px 100px 50px; }
  #cobra .box-envio h1 {
    color: #0399DB;
    font-weight: 600;
    font-size: 50px; }
  #cobra .box-envio p {
    color: #19253D;
    font-weight: normal;
    font-size: 22px; }
  #cobra .box-envio span {
    color: #FED201; }
  #cobra .slider_box {
    margin: 0 auto;
    background-color: #F6F6F6;
    width: 80%;
    padding: 7px;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3); }
    #cobra .slider_box .owl-theme .owl-dots,
    #cobra .slider_box .owl-theme .owl-nav {
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      bottom: 70px; }
  #cobra .blue_stuff {
    background-color: #0399DB;
    color: white;
    text-align: center;
    padding-bottom: 60px; }
    #cobra .blue_stuff h1 {
      font-weight: bolder;
      font-size: 50px; }
    #cobra .blue_stuff h3 {
      margin-top: 15px;
      font-weight: normal;
      font-size: 25px;
      margin-bottom: 50px; }
    #cobra .blue_stuff span {
      font-style: italic; }
    #cobra .blue_stuff .owl-theme .owl-dots,
    #cobra .blue_stuff .owl-theme .owl-nav {
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      bottom: -20px;
      margin-top: 15px; }

#beneficios {
  padding-top: 50px; }
  #beneficios .box-envio {
    padding: 100px 80px 100px 50px; }
  #beneficios .beneficios_copy,
  #beneficios .box-envio h1 {
    color: #0399DB;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 40px; }
  #beneficios .box-envio p {
    color: #19253D;
    font-weight: normal;
    font-size: 22px; }
  #beneficios span {
    font-style: italic; }
  #beneficios .beneficios_copy {
    text-align: center; }
  #beneficios select {
    margin: 0 auto;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: left; }

select {
  border: #E2E2E2 solid 1px;
  color: #6F6F6F; }

.proveedores {
  margin: 50px 0px; }

.proveedor {
  text-align: center;
  padding: 50px 15px;
  cursor: pointer;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  z-index: 1;
  background-color: white;
  height: 250px; }
  .proveedor h4 {
    color: #363636;
    font-weight: normal;
    font-size: 20px;
    padding: 20px; }
  .proveedor img {
    width: 80%; }

.proveedores .proveedor:nth-child(n+5) {
  border-top: 1px solid rgba(54, 54, 54, 0.582); }

.proveedores .proveedor:nth-child(4n-1),
.proveedores .proveedor:nth-child(4n-2),
.proveedores .proveedor:nth-child(4n) {
  border-left: 1px solid rgba(54, 54, 54, 0.582); }

.proveedores .proveedor:only-child {
  border: none; }

.proveedor:hover {
  border: 1px solid rgba(54, 54, 54, 0.582) !important;
  -webkit-transform: scale(1.2);
       -o-transform: scale(1.2);
          transform: scale(1.2);
  z-index: 100; }

.select {
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  /* Firefox 1-3.6 */
  border-radius: !important;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */ }

#formularios {
  color: #787878;
  font-size: 20px; }
  #formularios input[type=text],
  #formularios input[type=email] {
    box-shadow: none;
    float: left;
    width: 33%;
    font-style: italic;
    border-bottom: 2px solid #E2E2E2;
    border-top: 2px solid #E2E2E2;
    margin: 0;
    padding: 20px; }
  #formularios input[type=text]:nth-child(1) {
    border: 2px solid #E2E2E2; }
  #formularios input[type=email] {
    border: 2px solid #E2E2E2; }
  #formularios input[type=text]:nth-child(2) {
    border-left: none;
    border-right: none; }
  #formularios textarea {
    font-style: italic;
    padding: 20px;
    resize: none;
    width: 99%;
    float: left;
    height: 200px;
    border: 2px solid #E2E2E2;
    border-top: none; }
  #formularios #enviar {
    font-weight: bolder;
    font-size: 17px;
    margin: 40px auto;
    padding: 12px 75px;
    background-color: #0399db;
    color: #fff;
    border: none;
    box-shadow: none;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    border-radius: 91px;
    text-transform: uppercase; }
  #formularios #enviar:hover {
    background-color: #013044; }
  #formularios select {
    margin: 20px auto;
    width: 40%;
    font-style: italic;
    padding: 0px 15px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: left; }

#terminos {
  margin-top: 150px;
  margin-botton: 80px; }
  #terminos .panel-heading a:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    font-style: normal; }
  #terminos .panel-heading a.collapsed:after {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
       transform: rotate(180deg); }
  #terminos .panel-heading {
    background-color: white;
    border: 1px solid #c1c1c1;
    font-style: italic;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s; }
    #terminos .panel-heading a:hover {
      text-decoration: none; }
  #terminos .panel-heading:hover {
    background-color: whitesmoke; }
  #terminos .panel {
    margin: 40px 0px; }
  #terminos .panel-body,
  #terminos .panel {
    border: none;
    box-shadow: none; }
  #terminos h1 {
    color: #0098DB;
    font-size: 60px;
    text-align: center;
    font-weight: 700; }
  #terminos .actualizacion {
    color: #c1c1c1;
    font-size: 14px; }
    #terminos .actualizacion span {
      color: #FED201; }
  #terminos p {
    color: #19253D;
    font-size: 16px; }
  #terminos .button {
    margin-bottom: 60px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #0098da;
    margin: 20px auto;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    padding: 20px 50px;
    font-size: 20px; }
  #terminos .button:hover {
    background-color: #0074a7;
    text-decoration: none; }

p,
h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 300; }

h1 {
  font-family: 'Dosis', sans-serif; }

#puntos-envio p {
  font-weight: 300;
  font-size: 20px; }

.hero-image h2 {
  color: #fff;
  font-size: 19px;
  font-style: normal;
  font-weight: lighter;
  margin-top: -5px; }

#localiza-envio h2,
#punto_pago h2 {
  color: #19253d;
  font-size: 20px;
  font-weight: 300;
  width: 50%;
  margin: 35px auto;
  margin-bottom: 60px; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

#beneficios_modal {
  display: none; }

#carousel_puntos_cobro {
  margin-bottom: 50px; }

.item-puntos1 {
  width: 50%;
  text-align: right;
  float: left;
  background-color: #19a1e3;
  padding-right: 3%; }

.item-puntos2 {
  width: 50%;
  text-align: left;
  float: left;
  background-color: #19a1e3;
  padding-top: 10px;
  padding-left: 3%; }

.item-puntos3 {
  width: 100%;
  text-align: center;
  background-color: #19a1e3;
  height: 80px;
  padding-left: 3%;
  padding-bottom: 20px; }

#cobra .blue_stuff {
  background-color: #19a1e3;
  color: #fff;
  text-align: center;
  padding-bottom: 0px; }

#cobra .blue_stuff h3 {
  margin-top: 25px;
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 50px;
  font-family: 'Lato', sans-serif; }

#header-custom #navbar li a {
  color: #5a5a5a;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  font-family: 'Lato', sans-serif; }

#header-custom #navbar li a span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -23px;
  left: 0;
  background-color: #e8e8e8;
  -webkit-transform: scaleX(1);
       -o-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s; }

#cobra .box-envio p {
  color: #19253d;
  font-weight: 300;
  font-size: 20px; }

#cobra .box-envio a {
  color: #337ab7;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  font-style: italic; }

#cobra .box-envio a:hover {
  color: #fed201;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }

.proveedor {
  text-align: center;
  padding: 20px 15px;
  height: 250px; }

.proveedor h4 {
  color: #363636;
  font-weight: 300;
  font-size: 20px;
  padding: 20px;
  font-family: 'Lato', sans-serif; }

#beneficios .box-envio p {
  color: #19253d;
  font-weight: 300;
  font-size: 20px;
  font-family: 'Lato', sans-serif; }

#formularios h2 {
  color: #19253d;
  font-size: 20px;
  font-weight: lighter;
  width: 50%;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  margin: 35px auto;
  margin-bottom: 30px; }

footer hr {
  margin: 50px auto 10px;
  width: 70%;
  border: 2px solid #787878; }

#formularios #enviar {
  font-weight: bolder;
  font-size: 17px;
  margin: 40px auto;
  padding: 12px 75px;
  background-color: #0399db;
  color: #fff;
  border: none;
  box-shadow: none;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  border-radius: 91px;
  text-transform: uppercase; }

#puntos-envio {
  background-color: #19a1e3; }

.container_video,
.upper_box {
  background-image: -webkit-linear-gradient(top, #fff 0, #fff 10%, #fff 20%, #fff 30%, #fff 40%, #fff 49.99999999%, #19a1e3 50%);
  background-image: -o-linear-gradient(top, #fff 0, #fff 10%, #fff 20%, #fff 30%, #fff 40%, #fff 49.99999999%, #19a1e3 50%);
  background-image: linear-gradient(180deg, #fff 0, #fff 10%, #fff 20%, #fff 30%, #fff 40%, #fff 49.99999999%, #19a1e3 50%);
  padding: 50px 0; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 28px; }

.modal-header .close {
  margin-top: -2px;
  font-size: 30px; }

.modal-body {
  position: relative;
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Lato', sans-serif; }

.modal_title {
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  font-size: 16px; }

#boton {
  font-weight: bolder;
  font-size: 14px;
  margin: 5px auto;
  padding: 10px 70px;
  background-color: #0399db;
  color: #fff;
  border: none;
  box-shadow: none;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  border-radius: 91px;
  text-transform: uppercase; }

.mobile {
  display: none; }

@media screen and (max-width: 768px) {
  #terminos h1 {
    font-size: 30px; }
  .mobile {
    display: unset; }
  .desktop {
    display: none !important; }
  #terminos .button {
    margin: 12px auto;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    padding: 20px 50px;
    font-size: 11px; }
  footer hr {
    margin: 10px auto 10px !important; }
  #beneficios select {
    width: 70% !important; }
  #formularios select {
    margin: 20px auto;
    width: 70% !important;
    font-size: 17px !important;
    text-align: left; }
  input[type=text],
  input[type=email] {
    box-shadow: none;
    float: left;
    width: 100% !important;
    font-size: 17px !important;
    font-style: italic;
    margin: 0;
    padding: 20px; }
  input[type=text]:nth-child(1) {
    border: 2px solid #E2E2E2 !important; }
  input[type=email] {
    border-left: 2px solid #E2E2E2 !important;
    border-right: 2px solid #E2E2E2 !important;
    border-top: 2px solid #E2E2E2 !important; }
  input[type=text]:nth-child(2) {
    border-left: 2px solid #E2E2E2 !important;
    border-right: 2px solid #E2E2E2 !important;
    border-top: none !important;
    border-bottom: none !important; }
  textarea {
    font-style: italic;
    padding: 20px;
    resize: none;
    width: 99%;
    float: left;
    height: 200px;
    border: 2px solid #E2E2E2;
    border-top: none; }
  #formularios video,
  #beneficios video,
  #cobra video {
    width: 80%; }
  .buscador button,
  .buscador select {
    font-size: 12px; }
  #formularios h2 {
    width: 80%; }
  #formularios h1 {
    font-size: 38px; }
  .mobile_center {
    text-align: center; }
  #beneficios .beneficios_copy,
  #beneficios .box-envio h1 {
    font-size: 38px;
    margin-bottom: 21px; }
  #beneficios .box-envio {
    padding: 20px 40px; }
  #localiza-envio h2,
  #punto_pago h2 {
    width: 80%;
    margin: 35px auto;
    margin-bottom: 40px; }
  #localiza-envio h1,
  #punto_pago h1 {
    font-size: 38px;
    margin-top: 0px; }
  #cobra .slider_box .owl-theme .owl-dots,
  #cobra .slider_box .owl-theme .owl-nav {
    bottom: 20%; }
  .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 7px; }
  #cobra .box-envio {
    padding: 10px 40px; }
  #cobra .box-envio h1 {
    font-size: 40px; }
  #cobra .slider_box {
    width: 90%;
    padding: 3px; }
  #cobra,
  #beneficios {
    padding-top: 0; }
  #info img {
    width: 80%; }
  #play {
    width: 115px !important; }
  #puntos-envio .box-envio {
    padding: 10px 30px; }
  #video {
    height: 221px;
    width: 389px;
    padding: 2px; }
  #titulo-info {
    font-size: 35px; }
  .customPrevBtn,
  .customNextBtn {
    display: none; }
  #hero,
  .hero-image {
    width: 100%;
    height: 80vh; }
  .text-hero-left {
    width: 75%;
    position: unset;
    margin: 0 auto; }
  .text-hero-right {
    width: 75%;
    position: unset;
    margin: 0 auto; }
  .hero-image h1 {
    font-size: 22px; }
  .hero-image h2 {
    font-size: 17px; } }

body#tinymce {
  margin: 12px !important; }
