$s : #19253D;
$b : #0399DB;
$g : #787878;
footer{
    padding-bottom: 40px;
    h1{
        color: $b;
        font-size: 25px;
        font-weight: bolder;
    }
    .legales{
    display: block;
    margin: 5px auto;
    color: $g;
    font-size: 16px;
    transition: all 1s;
    }
    .legales:hover{
        color: $s;
        text-decoration: none;
    }
    .yellow{
        color: #FFD008;
        font-size: 16px;
    }
    .texto_yellow{
        color: $g;
        font-size: 14px;
    }
    hr{
        margin: 10px auto 10px auto;
        width: 70%;
        border: 2px solid $g;
    }
    h3{
        color: $g;
        font-size: 15px;
    }
    h4{
        color: $g;
        font-size: 18px;
        font-weight: 400;
        margin: 10px 0px;
    }
    .redes_sociales{
        color: #666666;
        padding: 10px;
        margin: 10px 0px 0px 0px;
        font-size: 16px;
        border-left:  1px solid  #E2E2E2;
        border-right:  1px solid  #E2E2E2;
        transition: all 1s;
    }
    .redes_sociales:hover{
        color: $b;
        text-decoration: none;
    }
    .redes_sociales:first-child,.redes_sociales:last-child, .redes_sociales:nth-child(3){
        border: none;
    }
}

@media screen and (max-width:768px){
    footer h3{
        text-align: center;
    }
    footer .text-right{
        text-align: center;
    }
}