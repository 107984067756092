$s : #19253D;
$b : #0399DB;
$g : #787878;

.magic_hover:hover>span,
.magic_hover:hover>i {
    transition: ease-in .4s;
    color: #7A2A00;
    text-decoration: none;
    font-weight: bold;
}

.logo {
    height: 55px;
    margin-top: 12.5px;
}

#header-custom .navbar-brand>span {
    color: white;
}

@media screen and (max-width: 992px) {
    #header-custom .navbar-brand {
        padding-left: 55px !important;
        padding-right: 0 !important;
    }

    #header-custom .navbar-brand:after,
    #header-custom .navbar-brand:before {
        margin-left: 25px;
    }
}

#header-custom.navbar-fixed-top {
    position: fixed !important;
    background: #fff;
    -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    top: 0;
}

#header-custom.navbar-fixed-top .navbar-brand {
    color: #cfc91d;
}

#header-custom.navbar-fixed-top #navbar li a {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

#header-custom.navbar-fixed-top #navbar li a:hover {
    color: pink;
}

@media screen and (max-width: 992px) {
    #header-custom.navbar-fixed-top #navbar li a {
        font-size: 20px;
    }
}

#header-custom.navbar-fixed-top #navbar li.active a {
    color: pink !important;
}

@media screen and (max-width: 768px) {
    #header-custom.navbar-fixed-top #navbar li.active a {
        color: pink !important;
    }
}

#header-custom.navbar-fixed-top #navbar li.active a span:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    background-color: pink;
}

@media screen and (max-width: 768px) {
    #header-custom.navbar-fixed-top #navbar li.active a span:before {
        background-color: transparent;
    }
}

.socialmedia {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #e2e2e2;
    padding-left: 70px;
    padding-right: 70px;
    min-height: 35px;

    .redes_sociales {
        color: #666666;
        padding: 5px;
        margin: 0px;
        font-size: 16px;
        border-left: 1px solid #E2E2E2;
        border-right: 1px solid #E2E2E2;
        transition: all 1s;
    }

    .redes_sociales:hover {
        color: $b;
        text-decoration: none;
    }

    .redes_sociales:first-child,
    .redes_sociales:last-child,
    .redes_sociales:nth-child(3) {
        border: none;
    }
}

#header-custom .navbar-default {
  border-bottom: 1px solid #e2e2e2;
    border: transparent;
    background: white;
    margin: 0;
    padding-left: 50px;
    height: 80px;
    padding-right: 50px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

@media screen and (max-width: 768px) {
    #header-custom .navbar-default {
        margin-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.social {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
    width: 100%;
}

.social li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.social li a {
    font-size: 16px;
    display: table;
    width: 40px;
    height: 40px;
    margin: 0 4px;
}

.social li a i {
    display: table-cell;
    vertical-align: middle;
}

.social li a:hover,
.social li a:active,
.social li a:focus {
    text-decoration: none;
    border-bottom: none;
}

.social li a.social-box {
    background: white;
    color: #fff;
}

.social li a.social-circle {
    background: white;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.social li a:hover {
    background: #393e46 !important;
}

.social.social-box a {
    background: white;
    color: #fff;
}

.social.social-circle a {
    background: white;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

#header-custom {
    position: fixed;
    border-bottom: 1px solid #e2e2e2;
    z-index: 99;
    width: 100%;
    opacity: 1;
    top: 0;
}

#header-custom .navbar {
    padding-bottom: 0;
    margin-bottom: 0;
}

#header-custom #navbar .navbar-right {
    margin-right: 0;
    text-align: center;
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .logo {
        margin-left: 30px;
    }

    .socialmedia {
        height: 40px;
        width: 100%;
        background-color: white;
        padding-left: 18px;
        padding-right: 18px;
        line-height: 30px;
    }
}

@media screen and (max-width: 992px) {
    #header-custom #navbar .navbar-right {
        padding-bottom: 20px;
    }
}

#header-custom #navbar li a {
    color: #5a5a5a;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    transition: all 1s;
}

@media screen and (max-width: 992px) {
    #header-custom #navbar li a {
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: 20px;
    }
}

#header-custom #navbar li a span {
    position: relative;
    display: block;
    padding-bottom: 2px;
}

#header-custom #navbar li a span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -23px;
  left: 0;
  background-color: #E8E8E8;
  /* visibility: hidden; */
  transform: scaleX(1);
  transition: all .3s ease-in-out 0s;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

#header-custom #navbar li a:hover {
    color: $s;
}

#header-custom #navbar li a:hover span:before {
    background-color: $s;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
}

#header-custom #navbar li.active a {
    background: transparent;
    background: none;
}

@media screen and (max-width: 480px) {
    #header-custom #navbar li.active a {}
}

#header-custom #navbar li.active a span:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

@media screen and (max-width: 480px) {
    #header-custom #navbar li.active a span:before {
        background-color: transparent;
    }
}

@media screen and (max-width: 992px) {
    #header-custom #navbar li.active a {
        background: transparent;
        background: none;
    }
}

@media screen and (max-width: 992px) and (max-width: 480px) {
    #header-custom #navbar li.active a {}
}

@media screen and (max-width: 992px) {
    #header-custom #navbar li.active a span {
        display: inline-block;
    }

    #header-custom #navbar li.active a span:before {
        bottom: 0;
        height: 0;
        background: transparent;
    }
}

#header-custom .navbar-brand {
    float: left;
    display: block;
    font-size: 24px;
    font-weight: 700;
    padding-left: 28px;
    color: #cfc91d;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
}

.nav-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-decoration: none;
}

.nav-toggle.active i::before,
.nav-toggle.active i::after {
    background: greenyellow;
}

.nav-toggle:hover,
.nav-toggle:focus,
.nav-toggle:active {
    outline: none;
    border-bottom: none !important;
}

.nav-toggle i {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    width: 25px;
    height: 3px;
    color: #535659;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #535659;
    transition: all .2s ease-out;
}

.nav-toggle i::before,
.nav-toggle i::after {
    content: '';
    width: 25px;
    height: 3px;
    background: #535659;
    position: absolute;
    left: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.nav-toggle i::before {
    top: -7px;
}

.nav-toggle i::after {
    bottom: -7px;
}

.nav-toggle:hover i::before {
    top: -10px;
}

.nav-toggle:hover i::after {
    bottom: -10px;
}

.nav-toggle.active i {
    background: transparent;
}

.nav-toggle.active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.nav-toggle.active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}

.nav-toggle {
    position: absolute;
    top: 22px;
    right: 30px;
    z-index: 21;
    padding: 6px 0 0 0;
    display: block;
    margin: 0 auto;
    display: none;
    height: 44px;
    width: 25px;
    border-bottom: none !important;
}

@media screen and (max-width: 768px) {
    .nav-toggle {
        display: block;
    }
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border-width: 0;
}

@media screen and (min-width: 768px) {
    .nav-tabs>li>a {
        border: none;
        color: pink;
        background-color: #56565A;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.nav-tabs>li.active>a,
.nav-tabs>li>a:hover {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border: none;
    color: pink !important;
    background: #F2F2F2;
}

.nav-tabs>li.active>a {
    background: #F2F2F2;
}

.nav-tabs>li.active {
    background: #F2F2F2;
}

.nav-tabs>li.active>a:active {
    background: #F2F2F2;
}

.tab-pane {
    padding: 15px 0;
}

@media screen and (min-width: 768px) {
    .tab-content {
        padding: 20px;
        background-color: #F2F2F2;
        height: 500px;
        padding-bottom: 100px;
        padding-right: 100px;
        padding-left: 100px;
    }
}

.card {
    background: #F2F2F2;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
    width: 25%;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    background-color: #F2F2F2;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border: none;
}

@media screen and (max-width:768px) {
    #header-custom #navbar li a:hover span:before {
        visibility: hidden;
        display: none;
    }

    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border: none;
        margin-top: 11.5px;
        background-color: white;
    }
    #header-custom #navbar li a span:before {
     display: none;
  }
}
